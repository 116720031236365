import React, { FC, useContext, useState } from 'react';
import "../../users.scss";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { getUserRolesOptions, StandardRoles, UserRolesEntity } from "../../models/user-roles.models";
import { confirmDialog } from "primereact/confirmdialog";
import { bodyTemplate } from "../../../../../common/components/tables";
import RightsWrapper from "../../../../auth/components/RightsWrapper";
import { onlyGlobalAdmin } from "../../../../../common/rights.consts";
import { AppStoreContext } from "../../../../../application/store/context";

interface UserRolesInfoProps {
    userId: number;
    data?: UserRolesEntity[] | null;
    roles?: UserRolesEntity[];
    addRole?: (role: string) => void;
    removeRole?: (role: string) => void;
}

const renderRoleBadge = (data: any, props: any) => {
    const role = data[props.field];
    const badge = StandardRoles.includes(role) ? role : "other";
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            <span className={`p-ml-2 user-badges role-${badge.toLowerCase()}`}>{role.toUpperCase()}</span>
        </>
    )
};

const UserRolesInfo: FC<UserRolesInfoProps> = ({ userId, data, roles, addRole, removeRole }) => {
    const appStore = useContext(AppStoreContext);

    const [ selectedRole, setSelectedRole ] = useState<string>();

    const confirmBlock = () => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
            },
            reject: () => {
            }
        });
    };

    const confirmRemoveRole = (role: string) => {
        confirmDialog({
            message: `Are you sure you want to remove role ${role}?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                role && removeRole && removeRole(role);
            },
            reject: () => {
            }
        });
    };

    const confirmAddRole = () => {
        confirmDialog({
            message: `Are you sure you want to add role ${selectedRole}?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                selectedRole && addRole && addRole(selectedRole);
            },
            reject: () => {
            }
        });
    }

    const actionTemplate = (data: UserRolesEntity) => {
        if (data.name === "srm_user") {
            return null;
        }
        if (data.name === "regular") {
            if (userId === appStore.authStore.userId) {
                return null;
            }
            return (
                <RightsWrapper rights={onlyGlobalAdmin} hideWrapper>
                    <div className="p-d-flex">
                        <Button
                            label="Block User"
                            icon="pi pi-trash"
                            className="p-button-primary p-mr-2"
                            onClick={confirmBlock}
                        />
                    </div>
                </RightsWrapper>
            )
        } else {
            return (
                <RightsWrapper rights={onlyGlobalAdmin} hideWrapper>
                    <div className="p-d-flex">
                        <Button
                            label="Remove Role"
                            icon="pi pi-trash"
                            className="p-button-primary p-mr-2"
                            onClick={() => confirmRemoveRole(data.name)}
                        />
                    </div>
                </RightsWrapper>
            );
        }
    };

    const renderRightEditBlock = () => {
        return (
            <RightsWrapper rights={onlyGlobalAdmin} hideWrapper>
                <Divider/>
                <div className="p-fluid">
                    <div className="p-field">
                        <Dropdown value={selectedRole}
                                  options={getUserRolesOptions(roles || [])}
                                  onChange={(e) => setSelectedRole(e.value)}
                                  placeholder="Select a Role"/>
                    </div>
                    <div className="p-field">
                        <p>
                            {roles && roles.find(r => r.name === selectedRole)?.description}
                        </p>
                    </div>
                    <div className="p-field">
                        <Button
                            label="Add role to user"
                            icon="pi pi-plus-circle"
                            className="p-button-primary p-mr-2"
                            onClick={confirmAddRole}
                            disabled={selectedRole === undefined}
                        />
                    </div>
                </div>
            </RightsWrapper>
        )
    }

    return (
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-6">
            <div className="card overview-box p-d-flex p-flex-column p-py-3">
                <div className="p-d-flex p-flex-row p-py-3 p-ai-center">
                    <div className="p-mx-2">
                        <i className="pi pi-key"
                           style={{ fontSize: "2rem" }}
                        />
                    </div>
                    <div className="p-d-flex p-flex-column p-ml-4">
                        <h5>Roles</h5>
                        <p>Info about user' roles</p>
                    </div>
                </div>
                <Divider/>
                <div className="p-d-flex p-flex-column datatable-responsive">
                    {
                        data &&
                        <DataTable
                            className="p-datatable-responsive-roles"
                            value={data}
                            dataKey="id"
                        >
                            <Column field="name" header="Role" body={renderRoleBadge}/>
                            <Column field="description" header="Description" body={bodyTemplate}/>
                            <Column headerStyle={{ width: '11rem' }}
                                    header="Actions"
                                    bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
                                    body={actionTemplate}
                            />
                        </DataTable>
                    }
                    {
                        renderRightEditBlock()
                    }
                </div>
            </div>
        </div>
    );
};

export default UserRolesInfo;
