import { UserModel } from "./user.models";
import { UserRolesEntity } from "./user-roles.models";

export interface UserDetailsAPIModel {
    id: number;
    details: UserModel[] | null;
    user_roles?: UserRolesEntity[] | null;
    profile: ProfileEntity[] | null;
    contacts?: ContactEntity[] | null;
    sessions?: SessionsEntity[] | null;
    identities?: IdentityEntity[] | null;
}

export interface UserDetailsModel {
    id: number;
    details?: UserModel | null;
    user_roles?: UserRolesEntity[] | null;
    profile?: ProfileEntity | null;
    contacts?: ContactEntity[] | null;
    sessions?: SessionsEntity[] | null;
    identities?: IdentityEntity[] | null;
}

export const userAPIModelToModel = (model: UserModel): UserModel => {
    return { ...model, created_date: new Date(model.created_date.toString()) }
}

export const userDetailsModelConvert = (data: UserDetailsAPIModel): UserDetailsModel => {
    return ({
        id: data.id,
        details: data.details && data.details.length > 0 ? userAPIModelToModel(data.details[0]) : undefined,
        user_roles: data.user_roles,
        profile: data.profile && data.profile.length > 0 ? data.profile[0] : undefined,
        contacts: data.contacts,
        sessions: data.sessions,
        identities: data.identities
    });
}

export interface ProfileEntity {
    user_id: number;
    nick_name?: null;
    first_name: string;
    patronymic_name?: null;
    family_name: string;
    birth_date?: null;
    gender?: null;
    position_id: number;
    company_id?: null;
    city_id: number;
    created_date: string;
    modified_date: string;
    raw_company: string;
    raw_company_url?: null;
    raw_position?: null;
    picture_link: string;
}

export interface ContactEntity {
    id: number;
    user_id: number;
    type: number;
    value: string;
    verification_status: number;
    origin?: string | null;
    used_by_default: boolean;
    created_date: string;
    modified_date: string;
}

export interface SessionsEntity {
    user_id: number;
    session_id: number;
    session_cookie: string;
    identity_value: string;
    user_agent: UserAgent;
    closed: boolean;
    open_date: string;
    close_date: string;
    duration: string;
}

export interface UserAgent {
    AgentName: string;
    DeviceCpu: string;
    AgentClass: string;
    DeviceName: string;
    DeviceBrand: string;
    DeviceClass: string;
    AgentVersion: string;
    LayoutEngineName: string;
    LayoutEngineClass: string;
    LayoutEngineVersion: string;
    OperatingSystemName: string;
    OperatingSystemClass: string;
    OperatingSystemVersion: string;
}

export interface IdentityEntity {
    id: number;
    type: string;
    value: string;
    user_id: number;
    created_date: string;
    modified_date: string;
    group_id: number;
}
