import React, { FC } from 'react';
import "../../users.scss";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UserTicketModel } from "../../models/user-tickets.models";
import { bodyTemplate, dateTemplate, moneyTemplate } from "../../../../../common/components/tables";

interface UserTicketsInfoProps {
    data?: UserTicketModel[] | null;
}

const renderCategoryBadge = (data: any, props: any) => {
    const string = data[props.field];
    return (
        <>
            <span className="p-column-title">Category</span>
            <span className={`user-badges category-${string.toLowerCase()}`}>{string.toUpperCase()}</span>
        </>
    )
};

const booleanTemplate = (data: any, props: any) => {
    const status = data[props.field].toString();
    return (
        <>
            <span className="p-column-title">FullPass</span>
            <span className={`user-badges bool-${status}`}>{data[props.field] ? "YES" : "NO"}</span>
        </>
    )
};

const eventTemplate = (data: UserTicketModel) => {
    return (
        <>
            <span className="p-column-title">Category</span>
            {data.jug_project + " " + data.jug_version}
        </>
    )
};

const UserTicketsInfo: FC<UserTicketsInfoProps> = ({ data }) => {
    return (
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
            <div className="card overview-box p-d-flex p-flex-column p-py-3">
                <div className="p-d-flex p-flex-row p-py-3 p-ai-center">
                    <div className="p-mx-2">
                        <i className="pi pi-ticket"
                           style={{ fontSize: "2rem" }}
                        />
                    </div>
                    <div className="p-d-flex p-flex-column p-ml-4">
                        <h5>Tickets</h5>
                        <p>Info about user' tickets</p>
                    </div>
                </div>
                <Divider/>
                <div className="p-d-flex p-flex-column datatable-responsive">
                    {
                        data &&
                        <DataTable
                            className="p-datatable-gridlines p-datatable-striped p-datatable-responsive"
                            value={data}
                            dataKey="ticket_id"
                            sortField="ticket_date"
                            sortOrder={-1}
                            emptyMessage="No tickets found"
                        >
                            <Column field="season"
                                    sortable
                                    header="Season" headerStyle={{ width: '7rem' }}
                                    body={bodyTemplate}
                            />
                            <Column field="year"
                                    sortable
                                    header="Year" headerStyle={{ width: '7rem' }}
                                    body={bodyTemplate}
                            />
                            <Column field="jug_project"
                                    sortable
                                    header="Event"
                                    body={eventTemplate}
                            />
                            <Column field="jug_crm_id"
                                    sortable
                                    header="Event Id" headerStyle={{ width: '6rem' }}
                                    body={bodyTemplate}
                            />
                            <Column field="ticket_id"
                                    header="Ticket Id"
                                    body={bodyTemplate}
                            />
                            <Column field="ticket_date"
                                    sortable
                                    header="Ticket Date" headerStyle={{ width: '13rem' }}
                                    body={dateTemplate}
                            />
                            <Column field="ticket_category"
                                    header="Category" headerStyle={{ width: '11rem' }}
                                    body={renderCategoryBadge}
                            />
                            <Column field="price"
                                    header="Price" headerStyle={{ width: '11rem' }}
                                    body={moneyTemplate}
                            />
                            <Column field="is_full_pass"
                                    body={booleanTemplate}
                                    header="FullPass" headerStyle={{ width: '6rem' }}
                            />
                            {/*<Column headerStyle={{ width: '11rem' }}*/}
                            {/*        header="Actions"*/}
                            {/*        bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}*/}
                            {/*        body={actionTemplate}*/}
                            {/*/>*/}
                        </DataTable>
                    }
                </div>
            </div>
        </div>
    );
};

export default UserTicketsInfo;
