import React, { FC, useContext } from 'react';
import { UserDetailsModel } from "../../models/user-details.models";
import "../../users.scss";
import { userStatusToString } from "../../models/user.models";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { onlyGlobalAdmin } from "../../../../../common/rights.consts";
import RightsWrapper from '../../../../auth/components/RightsWrapper';
import { confirmPopup } from "primereact/confirmpopup";
import { confirmDialog } from "primereact/confirmdialog";
import { resetUserPasswordAction } from "../../users.actions";
import { AppStoreContext } from "../../../../../application/store/context";
import { handleSuccessfully } from "../../../../notifications/notification.actions";

interface UserProfileInfoProps {
    data: UserDetailsModel;
}

const renderLangBadge = (lang: string) => {
    return (
        <span className={`p-ml-2 user-badges lang-${lang.toLowerCase()} p-text-uppercase`}>{lang}</span>
    )
};

const renderBoolBadge = (b: boolean) => {
    return (
        <span className={`p-ml-2 user-badges bool-${b.toString().toLowerCase()} p-text-uppercase`}>{b ? "YES" : "NO"}</span>
    )
};

const renderStatusBadge = (data: number) => {
    const status = userStatusToString(data);
    return (
        <span className={`p-ml-2 user-badges status-${status.toLowerCase()}`}>{status}</span>
    )
};

const renderDate = (date: Date | undefined) => {
    if (!date) {
        return <span className="p-ml-2">-</span>;
    }
    return <span className="p-ml-2">{date.toLocaleDateString("ru-RU") + " at " + date.toLocaleTimeString("ru-RU")}</span>
};

const UserProfileInfo: FC<UserProfileInfoProps> = ({ data }) => {
    const appStore = useContext(AppStoreContext);

    const resetPasswordHandler = () => {
        resetUserPasswordAction(appStore, data.id)
            .then(response => {
                if (response) {
                    renderPasswordResetSuccessConfirmDialog(true, response.password);
                }
            })
            .catch(() => {
                renderPasswordResetSuccessConfirmDialog(false);
            })
    }

    const renderPasswordResetConfirm = (event: any) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            dismissable: false,
            accept: resetPasswordHandler,
        });
    }

    const renderPasswordResetSuccessConfirmDialog = (success: boolean, password?: string) => {
        confirmDialog({
            message: success ? `Password resetting completed.
            Password is ${password}` : "Password resetting failed. Try again.",
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                if (password) {
                    {
                        window?.navigator.clipboard.writeText(password).then(() => {
                            handleSuccessfully(
                                appStore.notificationStore,
                                "Text copied to clipboard",
                                "Successfully",
                            );
                        })
                    }
                }
            },
            acceptLabel: success ? "Copy to clipboard" : "Close",
            rejectClassName: "p-d-none"
        });
    }

    const renderPasswordResetButton = () => {
        if (data.details?.status === 2) {
            return <span>Reset password for deactivated user not available</span>;
        }
        return (
            <Button className="p-ml-2"
                    onClick={renderPasswordResetConfirm}>Reset password for this user
            </Button>
        );
    };

    return (
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-6">
            <div className="card overview-box p-d-flex p-flex-column p-py-3">
                <div className="p-d-flex p-flex-row p-py-3 p-ai-center">
                    <div className="p-mx-2">
                        <i className="pi pi-briefcase"
                           style={{ fontSize: "2rem" }}
                        />
                    </div>
                    <div className="p-d-flex p-flex-column p-ml-4">
                        <h5>Profile</h5>
                        <p>Info about user</p>
                    </div>
                </div>
                <Divider/>
                <div className="p-d-flex p-flex-column">
                    <p>
                        <span className="p-text-bold">Communication email:</span>
                        <span className="p-ml-2">{data.details?.communication_email}</span>
                    </p>
                    <p>
                        <span className="p-text-bold">First name:</span>
                        <span className="p-ml-2">{data.profile?.first_name}</span>
                    </p>
                    <p>
                        <span className="p-text-bold">Family name:</span>
                        <span className="p-ml-2">{data.profile?.family_name}</span>
                    </p>
                    <p>
                        <span className="p-text-bold">Gender:</span>
                        <span className="p-ml-2">{data.profile?.gender || "unknow"}</span>
                    </p>
                    <p>
                        <span className="p-text-bold">Company:</span>
                        <span className="p-ml-2">{data.profile?.raw_company || "unknow"}</span>
                    </p>
                    <p>
                        <span className="p-text-bold">Language:</span>
                        {
                            renderLangBadge(data.details?.lang || "ru")
                        }
                    </p>
                    <p>
                        <span className="p-text-bold">Created Automatically:</span>
                        {
                            renderBoolBadge(data.details?.created_automatically || false)
                        }
                    </p>
                    <p>
                        <span className="p-text-bold">Status:</span>
                        {
                            renderStatusBadge(data.details?.status || 0)
                        }
                    </p>
                    <p>
                        <span className="p-text-bold">Created Date:</span>
                        {
                            renderDate(data.details?.created_date)
                        }
                    </p>
                    <p>
                        <span className="p-text-bold">Status comment:</span>
                        <span className="p-ml-2">{data.details?.comment}</span>
                    </p>
                    <RightsWrapper rights={onlyGlobalAdmin} hideWrapper>
                        <p className="p-d-flex p-ai-center">
                            <span className="p-text-bold">Password:</span>
                            {
                                renderPasswordResetButton()
                            }
                        </p>
                    </RightsWrapper>
                </div>
            </div>
        </div>
    );
};

export default UserProfileInfo;
