import { AuthStore } from "../../auth/auth.store";
import { fetchToGet, fetchToPost, fetchToPut } from "../../../common/lib/network";
import { UserModel, UsersStatsModel } from "./models/user.models";
import { UserDetailsAPIModel } from "./models/user-details.models";
import { resetPasswordAPIUrl, userListAPIUrl, usersDetailsAPIUrl, usersEditRolesAPIUrl, usersRolesListAPIUrl, usersStatsAPIUrl, usersTicketsAPIUrl, usersViolationsBaseAPIUrl } from "./users.api.routes";
import { UserTicketModel } from "./models/user-tickets.models";
import { UserRolesEntity } from "./models/user-roles.models";
import { UserViolationPutModel, UserViolationsApiModel } from "./models/user-violations.models";

export const getUsersFromAPI = (authStore: AuthStore, statuses: number[], globalFilter?: string, offset: number = 0, limit: number = 30) =>
    fetchToGet(
        userListAPIUrl(statuses, globalFilter, offset, limit).toString(),
        authStore.token
    )
        .then(response => response.json())
        .then(response => response as UserModel[]);

export const getUsersStatsFromAPI = (authStore: AuthStore) =>
    fetchToGet(
        usersStatsAPIUrl,
        authStore.token
    )
        .then(response => response.json())
        .then(response => response as UsersStatsModel[]);

export const getUserDetailsFromAPI = (authStore: AuthStore, id: number) =>
    fetchToGet(
        usersDetailsAPIUrl(id).toString(),
        authStore.token
    )
        .then(response => response.json())
        .then(response => response as UserDetailsAPIModel[])
        .catch(response => response.status === 404 ? null : undefined);

export const getUserTicketsFromAPI = (authStore: AuthStore, id: number) =>
    fetchToGet(
        usersTicketsAPIUrl(id).toString(),
        authStore.token
    )
        .then(response => response.json())
        .then(response => response as UserTicketModel[]);

export const getUserViolationsFromAPI = (authStore: AuthStore, sub: string) =>
    fetchToPost(
        usersViolationsBaseAPIUrl,
        {
            sub,
        },
        authStore.token
    )
        .then(response => response.json())
        .then(response => response as UserViolationsApiModel);

export const putUserViolationToAPI = (authStore: AuthStore, model: UserViolationPutModel) => {
    return fetchToPut(
        usersViolationsBaseAPIUrl,
        model,
        authStore.token
    )
        .then(response => response.json())
        .then(response => response as UserViolationsApiModel);
}

export const getUserRolesListFromAPI = (authStore: AuthStore) =>
    fetchToGet(
        usersRolesListAPIUrl,
        authStore.token
    )
        .then(response => response.json())
        .then(response => response as UserRolesEntity[]);

const setUserRoleToAPI = (authStore: AuthStore, userEmail: string, role: string, action: string) =>
    fetchToPost(
        usersEditRolesAPIUrl,
        {
            userEmail, // обязательный параметр, будет искать пользователя по Identity
            role,      // "company_regular" или "admin"
            action
        },
        authStore.token
    )
        .then(response => response.json())
        .then(response => {
            if (response.errors && response.errors.length > 0) {
                throw Error(`Failed with ${response.errors[0]?.message || 'unknow'}`);
            } else return response;
        })
        .then(response => response as {});

export const resetPasswordToApi = (authStore: AuthStore, userId: number) => fetchToPost(
    resetPasswordAPIUrl,
    {
        userId, // обязательный параметр, будет искать пользователя по Identity
    },
    authStore.token
)
    .then(response => response.json())
    .then(response => {
        if (response.errors && response.errors.length > 0) {
            throw Error(`Failed with ${response.errors[0]?.message || 'unknow'}`);
        } else return response;
    })
    .then(response => response.response as { password: string });

export const addUserRoleToAPI = (authStore: AuthStore, userEmail: string, role: string) =>
    setUserRoleToAPI(authStore, userEmail, role, "add");

export const removeUserRoleToAPI = (authStore: AuthStore, userEmail: string, role: string) =>
    setUserRoleToAPI(authStore, userEmail, role, "remove");
