import { AppStore } from "../../../application/store/app.store";
import { handleError, turnOffGlobalSpinner, turnOnGlobalSpinner } from "../../notifications/notification.actions";
import { info } from "../../../common/lib/logging";
import { addUserRoleToAPI, getUserDetailsFromAPI, getUserRolesListFromAPI, getUsersFromAPI, getUsersStatsFromAPI, getUserTicketsFromAPI, getUserViolationsFromAPI, putUserViolationToAPI, removeUserRoleToAPI, resetPasswordToApi } from "./users.api.actions";
import { UserDetailsModel, userDetailsModelConvert } from "./models/user-details.models";
import { UserViolationPutModel } from "./models/user-violations.models";

export const USER_NOTE_TITLE = "User handler";
export const initUserStatusesArray = [ 1 ];

export interface Option {
    label: string;
    value: string;
}

export const getUserListAction = async (
    appStore: AppStore,
    setLoading: (flag: boolean) => void,
    statuses: number[],
    globalFilter?: string,
    offset: number = 0,
    limit = 30,
) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    try {
        info(`[getUserListAction] start get page with offset ${offset} and limit ${limit} and statuses ${statuses} and filter ${globalFilter}`);

        return await getUsersFromAPI(authStore, statuses, globalFilter?.toLowerCase(), offset, limit);
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }

    return [];
}

export const getUsersStatsAction = async (
    appStore: AppStore,
    setLoading?: (flag: boolean) => void
) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    try {
        info(`[getUsersStatsAction] start get users stats`);

        const data = await getUsersStatsFromAPI(authStore);

        return data && data.length > 0 ? data[0] : undefined;
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }

    return undefined;
}

export const getUserDetailsAction = async (
    appStore: AppStore,
    id: number
): Promise<UserDetailsModel | null | undefined> => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        info(`[getUserDetailsAction] start get users details ${id}`);

        const data = await getUserDetailsFromAPI(authStore, id);
        if (!data) return data;

        return data.length > 0 ? userDetailsModelConvert(data[0]) : null;
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }

    return undefined;
}

export const getUserTicketsAction = async (
    appStore: AppStore,
    id: number,
) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        info(`[getUserticketsAction] start get users tickets for ${id}`);

        return await getUserTicketsFromAPI(authStore, id);
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }

    return undefined;
}

export const getUserViolationsAction = async (
    appStore: AppStore,
    sub: string,
) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        info(`[getUserViolationsAction] start get user violations for ${sub}`);

        return await getUserViolationsFromAPI(authStore, sub);
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }

    return undefined;
}

export const getUserRolesListAction = async (appStore: AppStore) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        info(`[getUserRolesListAction] start get users roles list`);

        return await getUserRolesListFromAPI(authStore);
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }

    return undefined;
}

export const addUserRoleAction = async (appStore: AppStore, email: string, role: string) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        info(`[setUserRoleAction] start set role to user`);

        return await addUserRoleToAPI(authStore, email, role);
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }

    return undefined;
}

export const removeUserRoleAction = async (appStore: AppStore, email: string, role: string) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        info(`[removeUserRoleAction] start remove role from user`);

        return await removeUserRoleToAPI(authStore, email, role);
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }

    return undefined;
}

export const resetUserPasswordAction = async (appStore: AppStore, userId: number) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        info(`[resetUserPasswordAction] start reset password for user`);

        return await resetPasswordToApi(authStore, userId);
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }

    return undefined;
}

export const removeUserViolation = async (appStore: AppStore, sub: string, name: string, eventId?: number) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        info(`[removeUserViolation] start remove user violation`);

        const model: UserViolationPutModel = {
            action: "remove",
            sub,
            violation: name,
            comment: null,
            eventId: !eventId ? null : eventId,
            from: null,
            to: null,
            violationReasonNumber: null
        };

        return await putUserViolationToAPI(authStore, model);
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }

    return undefined;
}

export const addUserViolation = async (appStore: AppStore,
                                       sub: string,
                                       name: string,
                                       eventId?: number,
                                       reasonId?: number,
                                       comment?: string,
                                       fromDate?: Date,
                                       toDate?: Date) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        info(`[addUserViolation] start add user violation`);

        const model: UserViolationPutModel = {
            action: "add",
            sub,
            violation: name,
            comment: !comment ? null : comment,
            eventId: !eventId ? null : eventId,
            from: !fromDate ? null : fromDate.toISOString(),
            to: !toDate ? null : toDate.toISOString(),
            violationReasonNumber: reasonId ?? null
        };

        return await putUserViolationToAPI(authStore, model);
    } catch (error) {
        handleError(notificationStore, error, USER_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }

    return undefined;
}
