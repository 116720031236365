import React, { useContext, useEffect, useState } from 'react';
import AppPage from '../../../../../application/components/AppPage';
import { AppStoreContext } from "../../../../../application/store/context";
import RightsWrapper from '../../../../auth/components/RightsWrapper';
import { userRights } from "../../../rights";
import { addUserRoleAction, addUserViolation, getUserDetailsAction, getUserRolesListAction, getUserTicketsAction, getUserViolationsAction, removeUserRoleAction, removeUserViolation } from "../../users.actions";
import { useParams } from 'react-router-dom';
import NotFoundPage from "../../../../../application/pages/NotFoundPage";
import { UserDetailsModel } from "../../models/user-details.models";
import { ProgressSpinner } from "primereact/progressspinner";
import AppCard from "../../../../../common/components/cards/AppCard";
import { countDaysBetweenDates } from "../../../../../common/lib/time";
import UserProfileInfo from "./UserProfileInfo";
import UserRolesInfo from "./UserRolesInfo";
import { UserTicketModel } from "../../models/user-tickets.models";
import UserTicketsInfo from "./UserTicketsInfo";
import { UserRolesEntity } from "../../models/user-roles.models";
import { UserViolationsApiModel } from "../../models/user-violations.models";
import UserViolationsInfo from "./UserViolationsInfo";

const UserDetailsPage = () => {
    const appStore = useContext(AppStoreContext);
    let { id } = useParams<{ id: string }>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ notFound, setNotFound ] = useState<boolean>(false);
    const [ data, setData ] = useState<UserDetailsModel>();
    const [ tickets, setTickets ] = useState<UserTicketModel[]>([]);
    const [ roles, setRoles ] = useState<UserRolesEntity[]>([]);
    const [ violations, setViolations ] = useState<UserViolationsApiModel>();

    useEffect(() => {
        setIsLoading(true);
        Promise.race([
            getUserDetailsAction(appStore, +id)
                .then(response => {
                    if (response) {
                        setData(response);
                    } else if (response === null) {
                        setNotFound(true);
                    }
                }),
            getUserRolesListAction(appStore)
                .then(response => {
                    if (response) {
                        setRoles(response);
                    }
                }),
            getUserTicketsAction(appStore, +id)
                .then(response => {
                    if (response) {
                        setTickets(response);
                    }
                }),
            getUserViolationsAction(appStore, `lk-oauth2|${id}`)
                .then(response => {
                    if (response) {
                        setViolations(response);
                    }
                })
        ])
            .finally(() =>
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            );
    }, [ appStore, id ]);

    // actions
    const removeViolation = (name: string, eventId?: number) => {
        data?.id && removeUserViolation(appStore, `lk-oauth2|${data.id}`, name, eventId)
            .then(response => {
                if (response) {
                    setViolations(response);
                }
            });
    }

    const addViolation = (name: string,
                          eventId?: number,
                          reasonId?: number,
                          comment?: string,
                          fromDate?: Date,
                          toDate?: Date) => {
        data?.id && addUserViolation(appStore, `lk-oauth2|${data.id}`, name, eventId, reasonId, comment, fromDate, toDate)
            .then(response => {
                if (response) {
                    setViolations(response);
                }
            });
    };

    // renders
    // return logic

    if (notFound) {
        return <NotFoundPage/>;
    }

    if (isLoading) {
        return (
            <div className="p-col-12 p-md-6 p-lg-6 p-xl-4">
                <ProgressSpinner/>
            </div>
        )
    }

    return (
        <RightsWrapper rights={userRights}>
            {
                <AppPage
                    header="User detail"
                    subheader={`You can view information about user ${data?.details?.communication_email || "NONAME"} `}
                    icon="pi-sort-alt"
                    headerComponent={
                        data ?
                            <AppCard title={data.details?.communication_email || "NONAME"}
                                     isLoading={isLoading}
                                     icon="pi-users"
                                     counters={
                                         [
                                             {
                                                 counter:
                                                     data.details?.created_date !== undefined ?
                                                         countDaysBetweenDates(data.details?.created_date, new Date()).toString() : "-",
                                                 title: "Days with us"
                                             }
                                         ]
                                     }
                                     className="deeppurple-bgcolor solid-surface-text-color"
                                     sizeClass="p-md-6 p-lg-6"
                                     component={
                                         data.profile?.picture_link ?
                                             <img src={data.profile.picture_link} alt="avatar" style={{ width: '64px', height: '64px' }}/>
                                             : undefined
                                     }
                            /> : <ProgressSpinner/>
                    }
                >
                    {
                        data && <UserProfileInfo data={data}/>
                    }
                    {
                        data && roles && <UserRolesInfo
                            userId={data.id}
                            data={data.user_roles}
                            roles={roles}
                            addRole={role => data.details && addUserRoleAction(appStore, data.details.communication_email, role)}
                            removeRole={role => data.details && removeUserRoleAction(appStore, data.details.communication_email, role)}
                        />
                    }
                    {
                        tickets && <UserTicketsInfo data={tickets}/>
                    }
                    {
                        violations && <UserViolationsInfo data={violations}
                                                          removeViolation={removeViolation}
                                                          addViolation={addViolation}
                        />
                    }
                </AppPage>
            }
        </RightsWrapper>
    );
};

export default UserDetailsPage;
